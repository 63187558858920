<template>
  <div>
    <div class="search-bar">
      <a-form-model :labelCol="{ span: 8 }"
                    :wrapperCol="{ span: 16 }"
                    ref="searchRef"
                    :model="searchForm">
        <a-row>
          <!-- 第一排 -->
          <a-col :span="6">
            <a-form-model-item prop="order_no" label="订单ID">
              <a-input v-model="searchForm.order_no" allowClear enter-button placeholder="请输入订单ID"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item prop="order_source" label="订单来源">
              <a-select placeholder="请选择订单来源" v-model="searchForm.order_source">
                <a-select-option
                  v-for="(item, index) in orderSourceOptions"
                  :key="index"
                  :value="item.id"
                >{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item prop="consignee_recipient_name" label="收/提货人">
              <a-input
                v-model="searchForm.consignee_recipient_name"
                allowClear
                placeholder="请输入收/提货人"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item prop="consignee_recipient_phone" label="收/提货手机号">
              <a-input
                v-model="searchForm.consignee_recipient_phone"
                allowClear
                placeholder="请输入手机号"
              ></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>

        <!-- 下单时间、收货时间 -->
        <a-row>
          <a-col :span="12">
            <a-form-model-item prop="order_add_time_arr" label="下单时间" 
                               :labelCol="{ span: 4 }" :wrapperCol="{ span: 20 }">
              <a-range-picker :show-time="{ format: 'HH:mm:ss' }" class="date-time"
                    :placeholder="['开始时间', '结束时间']"
                    v-model="searchForm.order_add_time_arr"
                    valueFormat="YYYY-MM-DD HH:mm:ss" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item prop="consignee_recipient_time_arr" label="收/提货时间"
                               :labelCol="{ span: 4 }" :wrapperCol="{ span: 20 }">
              <a-range-picker :show-time="{ format: 'HH:mm:ss' }" class="date-time"
                    :placeholder="['开始时间', '结束时间']"
                    v-model="searchForm.consignee_recipient_time_arr"
                    valueFormat="YYYY-MM-DD HH:mm:ss" />
            </a-form-model-item>
          </a-col>
        </a-row>

        <!-- 第二排 -->
        <a-row v-if="expand">
          <a-col :span="6">
            <a-form-model-item prop="order_status" label="订单状态">
              <a-select placeholder="请选择订单状态" v-model="searchForm.order_status">
                <a-select-option
                  v-for="(item, index) in orderStatusOptions"
                  :key="index"
                  :value="item.id"
                >{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item prop="orderer_phone" label="订货人电话">
              <a-input
                v-model="searchForm.orderer_phone"
                allowClear
                enter-button
                placeholder="请输入订货人电话"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item prop="consignee_address" label="收货地址">
              <a-input v-model="searchForm.consignee_address" allowClear placeholder="请输入收货地址"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item prop="order_remarks" label="下单备注">
              <a-input
                v-model="searchForm.order_remarks"
                enter-button
                allowClear
                placeholder="请输入备注"
              ></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>

        <!-- 第四排 -->
        <a-row v-if="expand">
          <a-col :span="6">
            <a-form-model-item prop="actual_sale_ascription_outlets_id" label="负责网点">
              <a-select show-search allowClear
                        placeholder="请输入负责网点"
                        :default-active-first-option="false"
                        :show-arrow="false"
                        :filter-option="false"
                        :not-found-content="null"
                        @search="initOutletsList"
                        v-model="searchForm.actual_sale_ascription_outlets_id">
                <a-select-option v-for="(item, index) in outletsList"
                                 :key="index"
                                 :value="item.outlets_id">{{item.outlets_name}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item prop="order_type" label="配送方式">
              <a-select placeholder="请选择配送方式" v-model="searchForm.order_type">
                <a-select-option
                  v-for="(item, index) in orderTypeOptions"
                  :key="index"
                  :value="item.id"
                >{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>

          <a-col :span="6">
            <a-form-model-item prop="acceptance_time_arr" label="受理时间">
              <a-range-picker v-model="searchForm.acceptance_time_arr" valueFormat="YYYY-MM-DD" />
            </a-form-model-item>
          </a-col>

          <a-col :span="6">
            <a-form-model-item prop="dispatch_staff_id" label="调度人">
              <a-select show-search allowClear
                        placeholder="请输入调度人"
                        :default-active-first-option="false"
                        :show-arrow="false"
                        :filter-option="false"
                        :not-found-content="null"
                        @search="getStaffList"
                        v-model="searchForm.dispatch_staff_id">
                <a-select-option v-for="(item, index) in staffList"
                                 :key="index"
                                 :value="item.staff_id">{{item.staff_name}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>

        <!-- 第5排 -->
        <a-row>
          <a-col :span="6">
            <a-form-model-item prop="pay_mode" label="支付方式">
              <a-select placeholder="请选择支付方式" v-model="searchForm.pay_mode">
                <a-select-option
                  v-for="(item, index) in orderModalOptions"
                  :key="index"
                  :value="item.id"
                >{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item prop="pay_flow_no" label="支付流水号">
              <a-input
                allowClear
                v-model="searchForm.pay_flow_no"
                enter-button
                placeholder="请输入支付流水号"
              ></a-input>
            </a-form-model-item>
          </a-col>

          <a-col :span="12">
            <a-form-model-item :labelCol="{ span: 4 }" :wrapperCol="{ span: 20 }"
                               prop="is_update" label="是否修改">
              <div class="flex justify-between items-center" style="flex:1;">
                <a-checkbox-group :options="orderIsModifyList" @change="hanlderCheckboxChange" />
                <div class="flex items-center justify-end mr-1 mt-1">
                  <a style="font-size:12px;" @click="handlerExpand">
                    {{!expand?'展开':'收起'}}
                    <a-icon :type="expand ? 'up' : 'down'" />
                  </a>
                  <a-button class="ml-4" @click="handlerResetForm">重置</a-button>
                  <a-button class="ml-2" type="primary" html-type="submit" @click="handlerSearch">搜索</a-button>
                </div>
              </div>
            </a-form-model-item>
          </a-col>
          
        </a-row>


      </a-form-model>
    </div>

    <base-table
      ref="orderCakeTableRef"
      id="orderCakeTableID"
      :columnsData="columns"
      :key="tableKey"
      :rowKey="rowKey"
      :tableData="tableData"
      :rowClassName="setRowClass"
      :total="total"
      :getListFunc="initData"
      :page.sync="searchForm.page"
      :pageSize.sync="searchForm.page_count"
      class="orderTableClass"
    >
      <template #toolbar>
        <div class="flex justify-between">
          <h4 class="text-lg font-bold">蛋糕订单列表</h4>
        </div>
        <div class="flex justify-between">
          <a-tabs type="card" class="w-full" @change="handlerTypeChange" :activeKey="activeKey">
            <a-tab-pane v-for="item in statusTypeList" :key="item.id" :tab="item.name"></a-tab-pane>
          </a-tabs>
          <a-button type="primary" @click="hanlderNewOrder" html-type="submit">新建订单</a-button>
        </div>
      </template>
      
      <template #order_status="{ text }">
        <!-- -2-已取消（旺店通需自行处理），-1-已取消，1-未支付，2-未审核，3-已分派，4-已完成） -->
        <a-tag class="mr-0"
          :color="text < 0 ? 'red' : (text < 3 ? '' : (text < 4 ? 'blue' : 'green')) "
        >{{ formatDetailOrderStatus(text) }}</a-tag>
      </template>
      <template #time="{ record }">
        <span>{{formatTime(record.consignee_recipient_time_start)}}~{{formatTime(record.consignee_recipient_time_end.split(' ')[1])}}</span>
      </template>

      <template #operation="{record}">
        <!-- <a v-if="[2,3].includes(record.order_status)" type="link" @click="handlerAuditBlank(record)">操作</a> -->
        <a v-if="[2,3,4].includes(record.order_status)" type="link" @click="handlerAuditBlank(record)">操作</a>
        <a v-else @click="handlerDetailBlank(record)">详情</a>
        <!-- <a v-if="record.order_status !=-1 && (record.is_deliverying==2 || record.is_express==2)"
          class="ml-2"
          type="link"
          @click="handlerQuery(record)"
        >配送查询</a> -->
      </template>
    </base-table>

    <!-- 抽屉展示审核 -->
    <a-drawer class="title-in-page"
              width="73vw"
              :get-container="false"
              :wrap-style="{ position: 'absolute', overflow:'hidden'}"
              placement="left"
              destroyOnClose
              :visible="isShowAudit"
              @close="isShowAudit = false"
            >
      <!-- <template slot="title">
        <div class="drawer-class">
          <div>{{`订单ID: ${activeOrderNO} (${formatDetailOrderStatus(activeRow.order_status)})`}}</div>
          <a-button
            type="link"
            class="cancelOrder-class"
            v-if="[1,2,3].includes(activeRow.order_status)"
            @click="handlerCancel(activeRow.order_no)"
          >取消订单</a-button>
        </div>
      </template> -->
      <orderAudit :orderNo="activeOrderNO" 
                  v-if="isShowAudit" 
                  @getDetail="(row)=>{activeRow=row}"
                  @ok="initData()"
                  @order-canceled="initData()"></orderAudit>
    </a-drawer>

    <!-- 抽屉展示详情-->
    <a-drawer class="title-in-page"
              :get-container="false"
              :wrap-style="{ position: 'absolute' }"
              width="73vw"
              placement="left"
              destroyOnClose
              :visible="isShowDetail"
              @close="isShowDetail = false"
            >
      <!-- <template slot="title">
        <div class="drawer-class">
          <div>{{`订单ID: ${activeOrderNO} (${formatDetailOrderStatus(activeRow.order_status)})`}}
            <template v-if="activeRow.order_status == 1 && activeRow.order_source == 10">
              <a-button type="link" @click="showPay=true">支付</a-button>
              <pay-tip />
            </template>
          </div>
          <a-button
            type="link"
            class="cancelOrder-class"
            v-if="[1,2,3].includes(activeRow.order_status)"
            @click="handlerCancel(activeRow.order_no)"
          >取消订单</a-button>
        </div>
      </template> -->
      <orderDetail :orderNo="activeOrderNO"
                   v-if="isShowDetail"
                   :num="detailKey"
                   @getDetail="(row)=>{activeRow=row}"
                   @order-canceled="initData()"></orderDetail>
    </a-drawer>
    
    <!-- 地图 -->
    <QueryWindow v-if="isShowQuery" :show.sync="isShowQuery" 
        :requestID="activeOrderNO" 
        :hasDelivery="activeOrder_hasDelivery" 
        :hasExpress="activeOrder_hasExpress" />

    <!-- 支付弹窗 -->
    <!-- <pay-modal v-if="showPay" :show.sync="showPay"
         :orderId="activeRow.order_no"
         :orderType="activeRow.pick_up_delivery_order.suborder_type"
         :totalAmount="Number(activeRow.order_amount)"
         @paystatus="getPayStatus"></pay-modal> -->

    <!-- 取消弹窗（包含其他支付） -->
    <!-- <cancel-modal v-if="isShowCancel"
         :show.sync="isShowCancel"
         :otherPayAmount="otherPayAmount"
         @ok="handlerCancelOtherPay" /> -->
  </div>
</template>

<script>
import {
  // cancelCakeOrder,
  getCakeOrderList,
  getOutletsList,
  getStaffList,
} from "@/api/customer"
import moment from "moment"
import pageData from "./columns"
// import PayTip from "@/components/order/pay-tip.vue"
import QueryWindow from "@/components/order/queryWindow"
// import CancelModal from '@/components/order/cancel-modal.vue'
import orderAudit from "./orderAudit.vue"
// import PayModal from '../order/components/pay-modal.vue'
import { formatDetailOrderStatus, payTypeList, orderTypeList, orderSourceList, orderStatusList, cakeOrderStatusList } from "@/utils/type"
import OrderDetail from "../order/detail.vue"

export default {
  components: { QueryWindow, orderAudit, OrderDetail },
  data() {
    return {
      expand: false,
      ...pageData,
      detailKey: 0,
      // showPay: false,
      activeOrderNO: "",
      activeOrder_hasDelivery: false,
      activeOrder_hasExpress: false,
      isShowAudit: false,
      isShowDetail: false,
      tableKey: 0,
      activeRow: {},

      orderTypeOptions: [{
          id: 0, 
          name: "全部" 
      }].concat(orderTypeList),
      orderModalOptions: [{
          id: 0, 
          name: "全部" 
      }].concat(payTypeList),
      orderSourceOptions: [{
          id: 0, 
          name: "全部" 
      }].concat(orderSourceList),
      orderStatusOptions: [{
          id: 0, 
          name: "全部" 
      }].concat(orderStatusList),

      isShowQuery: false,
      // changeOptions: ["用户修改", "客户修改", "未修改"],
      activeKey: 0,
      wrapperCol: { span: 19 },
      labelCol: { span: 5 },
      total: 0,
      tableData: [],
      outletsList: [],
      staffList: [],
      statusTypeList: cakeOrderStatusList,
      // statusTypeList: [
      //   { type_id: 0,  type_name: "全部" },
      //   { type_id: -1, type_name: "已取消" },
      //   { type_id: 2,  type_name: "自提审核" },
      //   { type_id: 3,  type_name: "配送审核" },
      //   { type_id: -2, type_name: "修改审核" },
      //   { type_id: 4,  type_name: "已分派" },
      //   { type_id: 9,  type_name: "已完成" },
      // ],
      searchForm: {
        order_no: "",
        // 订单来源 (0-全部，1-小程序商城，2-小程序门店，5-PC官网，6-手机官网，20-美团，21-饿了么，22-京东，23-天猫)
        order_source: 0,
        // 订单状态 （0-全部，-2-修改审核，-1-已取消，1-未支付，2-自提审核，3-配送审核，4-已分派，5-制作中，6-备货完成，7-配送中，8-快递配送，9-已完成）
        order_status: 0,
        // 是否修改 （1-用户修改，2-客服修改，3-未修改） 可传多个,分割
        is_update: "",
        orderer_phone: "",
        order_add_time_arr: [],
        order_add_time_start: "",
        order_add_time_end: "",
        consignee_recipient_time_arr: [],
        consignee_recipient_time_start: "",
        consignee_recipient_time_end: "",
        acceptance_time_arr: [],
        acceptance_time_start: "",
        acceptance_time_end: "",
        actual_sale_ascription_outlets_id: undefined,
        dispatch_staff_id: undefined,
        // 配送方式 （0-全部，1-自提，2-配送，3-快递）
        order_type: 0,
        pay_flow_no: "",
        // 支付方式 （0-全部，1-会员支付，2-微信支付，3-支付宝支付，10-货到付款)
        pay_mode: 0,
        page: 1,
        page_count: 20,
      },
      isShowEdit: false,
      isShowEditCurrent: false,
      activeSkuId: 0,

      currentUid: '',

      // isShowCancel: false,
      // otherPayAmount: 0,
    }
  },
  activated() {
    this.initData()
  },
  mounted() {
    let userInfo = this.$store.getters.userInfo
    this.currentUid = userInfo && userInfo.uid

    this.initData()

    // 如果传入orderId则默认展开该id的详情
    // if (this.$route.query.orderId) {
    //   this.activeOrderNO = this.$route.query.orderId
    //   this.isShowDetail = true
    // }
  },
  watch: {
    activeRow () {

    },
  },

  methods: {
    moment,
    formatDetailOrderStatus,

    // getPayStatus(){
    //   this.initData()
    //   ++this.detailKey
    //   this.isShowDetail = true
    //   this.showPay = false
    // },
    handlerTypeChange(e) {
      this.activeKey = e
      this.searchForm.order_status = e
      this.searchForm.page = 1
      this.initData()
    },
    async initData() {
      this.isShowDetail = false
      this.isShowAudit = false
      if (this.searchForm.order_add_time_arr.length > 0) {
        this.searchForm.order_add_time_start = this.searchForm.order_add_time_arr[0]
        this.searchForm.order_add_time_end = this.searchForm.order_add_time_arr[1]
      }
      if (this.searchForm.consignee_recipient_time_arr.length > 0) {
        this.searchForm.consignee_recipient_time_start = this.searchForm.consignee_recipient_time_arr[0]
        this.searchForm.consignee_recipient_time_end = this.searchForm.consignee_recipient_time_arr[1]
      }
      if (this.searchForm.acceptance_time_arr.length > 0) {
        this.searchForm.acceptance_time_start =
          this.searchForm.acceptance_time_arr[0] + " 00:00:00"
        this.searchForm.acceptance_time_end =
          this.searchForm.acceptance_time_arr[1] + " 23:59:59"
      }
      const { data, code } = await getCakeOrderList(this.searchForm)
      if (code === 0) {
        this.tableData = data.list
        this.total = data.total_count
      }
    },

    hanlderNewOrder() {
      // this.$router.push(`/order-add`)
      const newPage = this.$router.resolve({
        name: "order-add",
      });
      window.open(newPage.href, "_blank");
    },
    handlerSearch() {
      this.searchForm.page = 1
      this.initData()
    },
    handlerResetForm() {
      this.searchForm.page = 1
      this.searchForm.page_count = 10
      this.$refs.searchRef.resetFields()
      this.initData()
    },
    handlerAudit(e) {
      // this.activeRow = e
      this.activeOrderNO = e.order_no
      this.isShowAudit = true
    },
    handlerAuditBlank(row) {
      const newPage = this.$router.resolve({
        name: "orderAudit",
        query: { order_no: row.order_no },
      });
      window.open(newPage.href, "_blank");
    },

    handlerDetail(row) {
      this.activeOrderNO = row.order_no
      // this.activeRow = row
      this.isShowDetail = true
    },
    handlerDetailBlank(row) {
      const newPage = this.$router.resolve({
        name: "order-detail",
        query: { order_no: row.order_no },
      });
      window.open(newPage.href, "_blank");
    },
    // 物流
    handlerQuery(e) {
      this.activeOrderNO = e.order_no
      this.activeOrder_hasDelivery = e.is_deliverying==2
      this.activeOrder_hasExpress  = e.is_express==2
      this.isShowQuery = true
    },
    // 取消订单
    // handlerCancel() {
    //   const _this = this
    //   let order_no = this.activeRow.order_no
    //   let otherPayAmount = this.activeRow.other_pay_amount
    //       otherPayAmount = Number(otherPayAmount) || 0

    //   if(otherPayAmount > 0){
    //     this.otherPayAmount = otherPayAmount
    //     this.isShowCancel = true
    //   }else{
    //     this.$confirm({
    //       title: "温馨提示",
    //       content: "确定要取消订单吗?",
    //       okText: "确认",
    //       cancelText: "取消",
    //       onOk() {
    //         cancelCakeOrder({ order_no: order_no }).then(res => {
    //           if (res.code === 0) {
    //             _this.$message.success("已取消")
    //             _this.initData()
    //             ++_this.detailKey
    //             _this.isShowDetail = true
    //           }
    //         })
    //       },
    //     })
    //   }
    // },

    // handlerCancelOtherPay(e){
    //   const _this = this
    //   let params = { 
    //     order_no: this.activeRow.order_no,
    //     other_pay_amount: e.other_pay_amount
    //   }
    //   cancelCakeOrder(params).then(res => {
    //     if (res.code === 0) {
    //       _this.isShowCancel = false
    //       _this.$message.success("已取消")
    //       _this.initData()
    //       ++_this.detailKey
    //       _this.isShowDetail = true
    //     }
    //   })
    // },

    hanlderCheckboxChange(e) {
      this.searchForm.is_update = e.join(",")
    },
    async initOutletsList(value) {
      const params = {
        outlets_name: value,
      }
      if (value) {
        const { data, code } = await getOutletsList(params)
        if (code === 0) this.outletsList = data.list
      }
    },
    async getStaffList(value) {
      if (value) {
        const { data, code } = await getStaffList({
          staff_name: value,
        })
        if (code === 0) this.staffList = data.list
      }
    },

    handlerExpand() {
      this.expand = !this.expand
      ++this.tableKey
    },

    setRowClass (record) {
      // 修改审核 3
      // if (record.order_status == 'xx') {
      //   return "goods-update-status"
      // } else {
      //   return "goods-normal-status"
      // }
      if(record.dispatch_staff_id){
        return record.dispatch_staff_id == this.currentUid ? "row-mine":""
      }
      return ""
    },

    formatTime(time){
      if(/\d{2}:\d{2}:\d{2}/.test(time)){
        return time.slice(0, -3)
      }
    }
  },
}
</script>

<style>
.goods-update-status{
  background-color: bisque;
}
</style>
<style lang="less" scoped>
.drawer-class {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cancelOrder-class {
  margin-right: 50px;
}

.date-time{
  width: 100% !important;
}
</style>

